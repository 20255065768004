/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:17:23 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-02-08 14:51:26
 */
<template>
  <div class="order">
    <div class="width_1200">
      <div class="cont">
        <div class="bread">
          <bread-crumb></bread-crumb>
        </div>
        <div class="bgc_ffffff">
          <div class="padd">
            <div class="title">确认订单</div>
            <el-table
              :data="tableData.OrderDetail"
              :header-cell-style="{ background: '#F5F6F6', color: '#333333' }"
              :cell-style="{ padding: '24px 0' }"
              style="width: 100%"
            >
              <el-table-column label="课程信息">
                <template slot-scope="scope">
                  <div class="td_flex">
                    <img v-lazy="scope.row.CoverImg" alt="" />
                    <div class="fs_16 kcxx">{{ scope.row.GoodsName }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="价格" width="180">
                <template slot-scope="scope">
                  <div class="c_F40707">
                    ￥{{ scope.row.OriginalPrice | priceToFixed }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="数量" width="180">
                <template slot-scope="scope">
                  <div>×1</div>
                </template>
              </el-table-column>
              <el-table-column label="有效期" width="180">
                <template slot-scope="scope">
                  <div>有效期：{{ scope.row.ValidityDateEnd }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="address" label="实付" width="180">
                <template slot-scope="scope">
                  <div class="c_F40707">
                    ￥{{ scope.row.OriginalPrice | priceToFixed }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="puy_list">
              <!-- <el-row :gutter="25">
                <el-col :span="2" class="fs_16">购买类型</el-col>
                <el-col :span="2">
                  <el-radio v-model="buyTypes" label="1">普通课程</el-radio>
                </el-col>
                <el-col :span="2">
                  <el-radio v-model="buyTypes" label="2">vip课程</el-radio>
                </el-col>
              </el-row> -->
              <el-radio-group v-model="payModes" @change="handPayChange()">
                <el-row :gutter="25">
                  <el-col :span="2" class="fs_16">支付方式</el-col>
                  <el-col :span="3">
                    <el-radio label="0">
                      <img
                        src="../assets/images/pld.png"
                        class="pay"
                        alt=""
                      />
                      普利点支付
                    </el-radio>
                  </el-col>
                  <el-col :span="3" v-if="payType == '1'">
                    <span class="payType" @click="payTypes()"
                      >更多支付方式<i class="el-icon-d-arrow-right"></i
                    ></span>
                  </el-col>
                  <el-col :span="3" v-if="payType != '1'">
                    <el-radio label="1">
                      <img
                        src="../assets/images/alipay.png"
                        class="pay"
                        alt=""
                      />
                      支付宝支付
                    </el-radio>
                  </el-col>
                  <el-col :span="3" v-if="payType != '1'">
                    <el-radio label="2">
                      <img
                        src="../assets/images/wechat.png"
                        class="pay wechat"
                        alt=""
                      />
                      微信支付
                    </el-radio>
                  </el-col>
                </el-row>
              </el-radio-group>
             <!-- <el-radio-group v-model="buyModes" @change="handbuyChange()">
                <el-row :gutter="25">
                  <el-col :span="2" class="fs_16">购买方式</el-col>
                  <el-col :span="2">
                    <el-radio label="0">不分期</el-radio>
                  </el-col>
                  <el-col :span="2">
                    <el-radio label="1">分期</el-radio>
                  </el-col>
                </el-row>
              </el-radio-group> -->
              <el-row :gutter="25" type="flex" justify="end" class="xfje">
                <el-col :span="3">课程总价：</el-col>
                <el-col :span="4" class="fs_20"
                  >￥{{ tableData.OriginalPrice | priceToFixed }}</el-col
                >
              </el-row>
              <el-row :gutter="25" type="flex" justify="end" class="xfje">
                <el-col :span="3">优惠劵：</el-col>
                <el-col :span="4" class="c_F40707"
                  >-￥{{ tableData.DiscountMoney | priceToFixed }}</el-col
                >
              </el-row>
              <el-row :gutter="25" type="flex" justify="end" class="xfje">
                <el-col :span="3">手续费：</el-col>
                <el-col :span="4">￥{{ tableData.Fee | priceToFixed }}</el-col>
              </el-row>
              <el-row :gutter="25" type="flex" justify="end" class="xfje">
                <el-col :span="3">需付金额：</el-col>
                <el-col :span="4" class="c_F40707"
                  >￥{{ tableData.TotalMoney | priceToFixed }}</el-col
                >
              </el-row>
              <el-row :gutter="25" type="flex" justify="end" class="ljgm">
                <el-col :span="24">
                  <el-button @click="payment()" v-if="buyModes == '0'"
                    >立即支付</el-button
                  >
                  <el-button @click="payment()" v-else>提交审核</el-button>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <we-chat-payment :data="pay" :dialogVisible="dialogVisible" v-if="dialogVisible" @close="getClose()"></we-chat-payment>
    <div v-html="content"></div>
    <el-dialog
      title="支付成功"
      v-if="paymentDialogVisible"
      :visible.sync="paymentDialogVisible"
      width="30%" center
      :before-close="handleClose">
      <div class="paymentDialogVisible">
        <i class="el-icon-success"></i>
        <p>支付成功</p>
        <p class="times">{{times}} s后跳转到我的订单</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="goTo('/shopping/shoppingCart')">购课车</el-button>
        <el-button size="mini" type="primary" @click="goTo('/personal/myOrder')">我的订单</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import BreadCrumb from "@/components/breadcrumb.vue";
import WeChatPayment from "@/components/WeChatPayment.vue";
import { orderOrderStatement,orderAddOrder,orderQueryPayStatus } from "@/api/order";
export default {
  data() {
    return {
      levelList: [],
      tableData: [],
      payType: "1",
      payModes: "0",
      buyModes: "0",
      order: [],
      parm: {},
      content:"",
      dialogVisible:false,
      paymentDialogVisible:false,
      pay:{},
      timer:"",
      timerOne:"",
      times:10,
      user:{}
    };
  },
  components: {
    BreadCrumb,
    WeChatPayment
  },
  created() {},
  mounted() {
    this.user = JSON.parse(this.$store.state.userInfo);
    //2未填写个人信息时，先填写个人信息
    if (this.user.IdCardNum == "" && this.user.IdCardNum == null) {
      this.$message.info("请完善个人信息");
      let obj = {
        perfect:1,//1完善个人信息
      }
      setTimeout(()=>{
        this.$router.push({ path: "/personal/personalData", query: obj });
      },2*1000)
    }else{
      this.init();
    }
  },
  methods: {
    goTo(url) {
      this.$router.push({ path: url });
    },
    handleClose(){
          this.paymentDialogVisible = false;
    },
    getClose(){
      this.dialogVisible = false;
    },
    async init() {
      this.order = JSON.parse(this.$route.query.data);
      this.parm = {
        paymentMethod: this.payModes, //支付方式（0普利点1支付宝2微信3苹果）
        orderSource: "PC", //支付来源（PC、APP）
        isInstallment: this.buyModes, //是否分期（1是0否）
        orderDetail: [],
        orderCarIds: [], //0课程1资料2题库
      };
      this.order.forEach((item, i) => {
        // console.log(item.Immediately, '')
        this.parm.orderDetail[i] = {
          goodsId: item.GoodsId == undefined ? item.Id : item.GoodsId,//GoodsId商品id
          isVip: item.IsVip,
          goodsCategory: item.GoodsCategory,
          quantity: 1,
        };
        if (this.order[0].Immediately == undefined) {
          this.parm.orderCarIds.push(item.Id);
        }
      });
      const res = await orderOrderStatement(this.parm);
      if (res.success === true) {
        this.tableData = res.response;
        this.payModes = this.tableData.PaymentMethod + "";
        this.buyModes = this.tableData.IsInstallment + "";
      } else {
        this.$message.error(res.msg);
      }
    },
    handPayChange(){
      this.init();
    },
    handbuyChange(){
      this.init();
    },
    // 根据支付id查询支付状态
    async postorderQueryPayStatus(obj){
      let parm = {
        id: obj.Id,
        isInstallment:obj.IsInstallment,
      }
      const res = await orderQueryPayStatus(parm);
      if (res.response === true) {
        this.dialogVisible = false;
        this.$message.success("支付成功");
        setTimeout(()=>{
          this.getPaymentDialogVisible();
        },500)
      }else{
        // this.$message.error(res.msg);
      }
    },
    // 立即支付
    payment() {
      this.$confirm('是否继续支付?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.continueToPay();
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消支付'
          });
        });
    },
    async continueToPay(){
      let parm = {
        paymentMethod: this.tableData.PaymentMethod + "", //支付方式（0普利点1支付宝2微信3苹果）
        orderSource: "PC", //支付来源（PC、APP）
        isInstallment: this.tableData.IsInstallment + "", //是否分期（1是0否）
        orderDetail: [],
        orderCarIds: [], //0课程1资料2题库
      };
      this.tableData.OrderDetail.forEach((item, i) => {
        parm.orderDetail[i] = {
          goodsId: item.GoodsId,
          isVip: item.IsVip,
          goodsCategory: item.GoodsCategory,
          quantity: 1,
        };
        // if (this.order[0].Immediately == undefined) {
        //   parm.orderCarIds.push(item.Id);
        // }
      });
      parm.orderCarIds = this.tableData.OrderCarIds;
      const res = await orderAddOrder(parm);
      if (res.success === true) {
        // this.$message.success(res.msg);
        if (this.payModes === "0") {
          this.$message.success(res.msg);
          setTimeout(()=>{
            this.getPaymentDialogVisible();
          },500)
        }else if(this.payModes === "1"){
          this.content = res.response.PayData;
          setTimeout(() => {
            // 获取到后端返回的form表单name名称,可以自己输出查看
            document.getElementsByName('submit')[0].setAttribute('target', '_blank'); // 新窗口打开
            document.getElementsByName('submit')[0].submit();
          }, 500)
          setTimeout(()=>{
            this.getPaymentDialogVisible();
          },500)
        }else if(this.payModes === "2"){
          setTimeout(() => {
            this.dialogVisible = true;
            this.pay.code = res.response.PayData;
            this.pay.TotalMoney = this.tableData.TotalMoney;
            this.pay.order = "订单";
          }, 500)
          this.timer = setInterval(() => {
            this.postorderQueryPayStatus(res.response);
          }, 3 * 1000)
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    getPaymentDialogVisible() {
      this.dialogVisible = false;
      this.paymentDialogVisible = true;
      this.timerOne = setInterval(() => {
        this.times--;
        if(this.times===0){
          this.$router.push({ path: "/personal/myOrder"});
          clearInterval(this.timer)
        }
      }, 1000)
    },
    //更多支付方式
    payTypes() {
      this.payType = "2";
    },
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.timerOne);
  }
};
</script>

<style lang="less" scoped>
.order {
  // margin-top: -30px;
  // min-height: calc(100vh - 230px);
  height: auto;
  background-color: #f5f5f5;
  box-shadow: inset 0px 30px 21px -30px rgba(110, 110, 110, 0.2);
  .cont {
    .padd {
      padding: 0 30px;
      .title {
        font-size: 16px;
        font-weight: bold;
        line-height: 56px;
        margin-bottom: 25px;
      }
      /deep/ .el-table {
        font-size: 14px;
        color: #333333;
        margin-bottom: 40px;
        &::before {
          height: 0;
        }
        th,
        tr,
        td {
          text-align: center;
          border: 0;
        }
        .td_flex {
          display: flex;
          align-items: center;
          img {
            width: 160px;
            height: 90px;
          }
          .kcxx {
            text-align: left;
            margin-left: 20px;
            line-height: 150%;
          }
        }
      }
      .puy_list {
        .el-radio-group {
          width: 100%;
        }
        .el-row {
          line-height: 30px;
          margin-bottom: 20px;
          .el-col {
            .pay {
              width: 30px;
              height: 30px;
              vertical-align: middle;
              margin-right: 6px;
            }
            .wechat {
              height: 27px;
            }
            .payType {
              font-size: 12px;
              cursor: pointer;
              color: #999999;
            }
          }
        }
        .xfje {
          font-size: 16px;
          margin-bottom: 0;
          line-height: 35px;
          &:nth-child(1) {
            margin-top: 30px;
          }
          .el-col {
            text-align: right;
          }
        }
        .ljgm {
          padding: 20px 0 30px;
          .el-col {
            text-align: right;
            .el-button {
              width: 120px;
              height: 40px;
              background-color: #e62929;
              border-radius: 4px;
              font-size: 16px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
.paymentDialogVisible{
  text-align: center;
  i{
    color: #168AF4;
    font-size: 88px;
  }
  p{
    margin: 10px 0;
  }
  .times{
    color: #999999;
    font-size: 12px;
  }
}
</style>