/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:29:00 
 * @Last Modified by:   liuzhixiang 
 * @Last Modified time: 2021-02-06 15:29:00 
 */
import http from './http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "/api/api/v1"

let loading = {showLoading: false }

// 获取购课车列表
export async function orderQueryCourseCarList(params) {
    let res = await http.get(`${resquest}/Order/QueryCourseCarList`, params)
    return res.data;
}

// 添加课程到购课车
export async function orderSaveCourseCar(params) {
    let res = await http.post(`${resquest}/Order/SaveCourseCar`, params)
    return res.data;
}

// 删除购课程中课程
export async function orderDeleteCourseCar(params) {
    let res = await http.post(`${resquest}/Order/DeleteCourseCar`, params)
    return res.data;
}

// 订单结算接口
export async function orderOrderStatement(params) {
    let res = await http.post(`${resquest}/Order/OrderStatement`, params)
    return res.data;
}

// 新增订单
export async function orderAddOrder(params) {
    let res = await http.post(`${resquest}/Order/AddOrder`, params)
    return res.data;
}

// 获取订单列表
export async function orderQueryOrderList(params) {
    let res = await http.get(`${resquest}/Order/QueryOrderList`, params)
    return res.data;
}

// 待支付订单重新支付
export async function orderOrderPay(params) {
    let res = await http.post(`${resquest}/Order/OrderPay`, params)
    return res.data;
}

// 关闭订单
export async function orderCloseOrder(params) {
    let res = await http.get(`${resquest}/Order/CloseOrder?Id=`+ params)
    return res.data;
}

// 查询支付状态
export async function orderQueryPayStatus(params) {
    let res = await http.post(`${resquest}/Order/QueryPayStatus`, params,loading)
    return res.data;
}

// 获取订单分期信息
export async function orderQueryInstallmentOrderDetail(params) {
    let res = await http.get(`${resquest}/Order/QueryInstallmentOrderDetail?orderId=`+ params)
    return res.data;
}

// 分期订单支付
export async function orderInstallmentOrderPay(params) {
    let res = await http.post(`${resquest}/Order/InstallmentOrderPay`, params)
    return res.data;
}